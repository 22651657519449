exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-application-development-services-js": () => import("./../../../src/pages/application-development-services.js" /* webpackChunkName: "component---src-pages-application-development-services-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-study-page-js": () => import("./../../../src/pages/case-study-page.js" /* webpackChunkName: "component---src-pages-case-study-page-js" */),
  "component---src-pages-cloud-native-application-development-js": () => import("./../../../src/pages/cloud-native-application-development.js" /* webpackChunkName: "component---src-pages-cloud-native-application-development-js" */),
  "component---src-pages-digital-transformation-services-js": () => import("./../../../src/pages/digital-transformation-services.js" /* webpackChunkName: "component---src-pages-digital-transformation-services-js" */),
  "component---src-pages-healthcare-software-development-js": () => import("./../../../src/pages/healthcare-software-development.js" /* webpackChunkName: "component---src-pages-healthcare-software-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-test-blog-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/test-blog/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-content-blog-test-blog-index-mdx" */),
  "component---src-pages-product-engineering-and-development-js": () => import("./../../../src/pages/product-engineering-and-development.js" /* webpackChunkName: "component---src-pages-product-engineering-and-development-js" */),
  "component---src-pages-request-quote-js": () => import("./../../../src/pages/request-quote.js" /* webpackChunkName: "component---src-pages-request-quote-js" */),
  "component---src-pages-software-development-services-js": () => import("./../../../src/pages/software-development-services.js" /* webpackChunkName: "component---src-pages-software-development-services-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */)
}

